@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

* {
  outline: none;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-width: 1200px;
}

@layer base {
  .button {
    @apply px-4 py-2 min-w-max max-w-max text-xs font-semibold flex items-center normal-case !important;
  }

  .button-text {
    @apply p-0 !important;
  }

  .button-delete svg path {
    stroke: #fff !important;
  }

  .populate-select {
    @apply border bg-white;
  }
  .has-error .populate-select {
    @apply border-red-500;
  }

  .search-icon {
    @apply h-4 w-4 !important;
  }

  /** Material Overrides */
  /** Select */
  .populate-select:not(.disabled):hover,
  .populate-autocomplete:hover fieldset,
  .populate-input:hover fieldset {
    @apply !border-gray-600;
  }

  .populate-select.Mui-focused,
  .populate-autocomplete:focus-within fieldset,
  .populate-input:focus-within fieldset {
    @apply !border-gray-400;
  }

  .populate-select > .MuiSelect-select.MuiOutlinedInput-input {
    @apply text-xs pl-3 py-[7px] text-primary;
  }

  .populate-select.disabled > .MuiSelect-select.MuiOutlinedInput-input {
    @apply bg-white !border-gray-700 !text-black;
    -webkit-text-fill-color: #393939 !important;
  }

  .populate-select > .MuiSelect-icon {
    @apply fill-current;
  }

  .populate-select.disabled {
    @apply text-center;
  }

  .populate-select.disabled > .MuiSelect-icon {
    @apply !text-gray-500;
  }

  .populate-select > fieldset {
    @apply border-none;
  }
  /** Select End */

  /** Autocomplete */
  .populate-autocomplete .MuiOutlinedInput-root {
    @apply text-sm font-medium text-primary;
  }

  .populate-autocomplete .MuiOutlinedInput-input {
    @apply p-0 !important;
  }

  .populate-autocomplete .MuiOutlinedInput-root {
    @apply p-2 font-normal text-xs;
  }

  .populate-autocomplete fieldset,
  .button-outlined,
  .populate-input fieldset {
    @apply !border !border-gray-200;
  }

  .populate-autocomplete:focus-within fieldset {
    @apply !border-gray-400;
  }

  .populate-autocomplete.disabled {
    @apply !bg-gray-100;
  }
  .populate-autocomplete.disabled input {
    -webkit-text-fill-color: #393939 !important;
    @apply !text-[#393939];
  }

  .populate-autocomplete .MuiInputBase-input[readonly] {
    @apply !text-center;
  }

  .populate-autocomplete.is-dirty fieldset {
    @apply border-secondary border-2 !important;
  }

  .populate-autocomplete .MuiAutocomplete-endAdornment {
    @apply top-[calc(50%-0.65rem)] !important;
  }
  .populate-autocomplete .MuiAutocomplete-popupIndicator {
    @apply rotate-0 opacity-80 !important;
  }
  .populate-autocomplete .populate-input-placeholder::placeholder {
    @apply !opacity-100;
  }
  /** Autocomplete End */

  /** Accordion */
  .populate-accordion.MuiPaper-root.MuiAccordion-root {
    @apply shadow-none border border-gray-200 rounded-md
  }
  .populate-accordion.MuiPaper-root.MuiAccordion-root.rounded-0 {
    @apply !rounded-none;
  }
  .populate-accordion:not(:first-of-type):before {
    @apply !hidden;
  }
  .populate-accordion .MuiAccordionSummary-root.Mui-expanded {
    @apply !min-h-0;
  }
  .populate-accordion .MuiAccordionSummary-contentGutters {
    @apply !my-4;
  }
  /** Accordion End */

  /** TextField Start */
  .populate-input {
    @apply w-full !important;
  }

  .populate-input .Mui-disabled {
    @apply bg-gray-100 border-gray-200 !important;
    -webkit-text-fill-color: #393939 !important;
    color: #393939 !important;
  }

  .populate-input:hover .Mui-disabled fieldset {
    @apply !border-gray-200;
  }

  .populate-input .MuiOutlinedInput-input {
    @apply p-2 !important;
  }

  .populate-input.populate-small-input .MuiOutlinedInput-input {
    @apply !py-0.5;
  }

  .populate-input.no-padding .MuiOutlinedInput-input {
    @apply p-0 !important;
  }

  .transparent-input,
  .populate-input.transparent-input fieldset {
    @apply !border-transparent;
  }

  .transparent-input .populate-textarea,
  .populate-input.transparent-input .MuiOutlinedInput-input {
    @apply !border-transparent !border border-solid !rounded hover:!border-gray-300 focus:!border-gray-400;
  }

  .populate-input .MuiOutlinedInput-root {
    @apply font-normal text-xs !important;
  }

  .populate-input.no-border fieldset {
    @apply border-0 !important;
  }

  .populate-input.h-full  {
    @apply border-0 !important;
  }

  .populate-input.is-small .MuiOutlinedInput-input {
    @apply !h-4 text-xs !p-0;
  }

  .populate-input.is-small .MuiOutlinedInput-input + fieldset {
    @apply !border-none;
  }
  .populate-textarea {
    @apply hover:border-gray-600;
  }
  .populate-textarea:focus-within {
    @apply !border-gray-400;
  }

  /** HAS ERROR START */
  .has-error label,
  .has-error .MuiSelect-outlined {
    @apply !text-red-500;
  }
  .has-error .MuiInputBase-root fieldset {
    @apply !border-red-500 !border rounded;
  }
  .has-error .error-message {
    @apply !text-red-500 font-medium;
  }
  /** HAS ERROR END */

  /** Date Picker */
  .populate-date-picker > .MuiOutlinedInput-root {
    @apply text-xs;
  }

  .populate-date-picker.populate-date-picker-with-nav > .MuiOutlinedInput-root {
    @apply rounded-none h-[34px];
  }

  .populate-date-picker:not(.populate-date-picker-with-nav) > .MuiOutlinedInput-root {
    @apply rounded;
  }

  .populate-date-picker .MuiInputBase-input {
    @apply py-2 px-2.5 pr-0 border-none;
  }

  .populate-date-picker .Mui-error fieldset {
    @apply !border-inherit;
  }

  .populate-date-picker.bold .MuiInputBase-input {
    @apply font-semibold;
  }

  .populate-date-picker .MuiButtonBase-root {
    @apply p-1;
  }

  .populate-date-picker .MuiSvgIcon-root {
    @apply h-5 w-5 text-primary;
  }

  .populate-date-picker .MuiOutlinedInput-notchedOutline {
    @apply border-gray-200;
  }

  .populate-date-picker:focus-within fieldset {
    @apply border border-gray-400 !important;
  }

  .custom-calendar .MuiClock-root .MuiClock-amButton,
  .custom-calendar .MuiClock-root .MuiClock-pmButton {
    @apply !relative !ml-2 !inset-[initial];
  }
  /** Date Picker End */

  /** Tootlip */
  .tooltip-theme-light .MuiTooltip-tooltip {
    @apply bg-white text-black shadow-md;
  }

  .tooltip-theme-light .MuiTooltip-arrow {
    @apply text-white;
  }

  .billing-code-content .MuiTooltip-arrow {
    @apply text-populate-light-blue-2;
  }

  .billing-code-content .MuiTooltip-tooltip {
    @apply !ml-10 bg-populate-light-blue-2;
  }
  /** Tooltip End */

  /** Drawer */
  .detailed-entity-drawer {
    @apply !absolute !top-[initial];
  }

  .detailed-entity-drawer .MuiPaper-root:not(.MuiAccordion-root, .MuiAlert-root) {
      @apply top-3 right-3 rounded-xl !w-[40rem] h-[calc(100%-1.5rem)];
  }

  .detailed-entity-drawer.has-extension .MuiPaper-root:not(.MuiAccordion-root, .MuiAlert-root) {
    @apply !w-auto !max-w-[70rem] !min-w-[40rem];
  }

  .detailed-entity-drawer.has-extension .populate-panel-extension {
    @apply w-0;
  }

  .MuiPaper-root:not(.MuiAccordion-root, .MuiAlert-root).transmission-message {
      @apply !w-[34rem] !my-2;
  }
  /** Drawer End */

  /** Navigation Tabs */
  .populate-navigation-tabs .populate-navigation-tab {
    @apply !text-gray-600;
    border: 1px solid transparent;
  }
  .populate-navigation-tabs .populate-navigation-tab.selected {
    @apply !border-black;
  }
  /** Navigation Tabs End */
  .populate-route-tabs .MuiTabs-indicator {
    @apply bg-blue-500;
  }

  .populate-route-tabs .MuiTab-root {
    @apply !text-gray-500 !text-xs !min-w-[84px];
  }

  .populate-route-tabs .Mui-selected {
    @apply !text-blue-500 !text-xs;
  }

  .populate-tabs {
    @apply min-h-[1.75rem];
  }

  .populate-tabs .MuiTabScrollButton-horizontal, .populate-tabs .MuiTabs-indicator {
    @apply !hidden;
  }

  .populate-tabs .MuiTabs-flexContainer > button.MuiTab-root {
    @apply capitalize py-1.5 px-2 min-h-[1.75rem] text-xs min-w-[5rem];
  }

  .populate-tabs .MuiTabs-flexContainer > button.MuiTab-root.Mui-selected {
    @apply border border-solid border-[#151515] rounded-md;
  }
}

.auto-scrollable * {
  scroll-behavior: auto !important;
}

/** Dialog Start */
.populate-dialog .MuiPaper-root.MuiDialog-paper:not(.MuiDialog-paperFullWidth) {
  @apply !rounded-lg !max-w-max;
}

.populate-dialog .MuiDialog-paper {
  @apply !max-h-[calc(100%-24px)];
}

.populate-dialog.file-viewer.is-preview .MuiPaper-root {
  @apply !h-[90vh] !max-h-none !max-w-none;
}

.populate-dialog.large-width .MuiPaper-root {
  @apply w-[40rem] !important;
}

.populate-dialog.hidden-backdrop .MuiBackdrop-root {
  @apply !hidden;
}

/** Dialog End */

/** iFrame Start */
.populate-iframe-modal {
  @apply !left-[72px] !w-[calc(100%-72px)] transition-all duration-100 ease-in;
}

.is-expanded ~ .populate-iframe-modal {
  @apply !left-[210px] !w-[calc(100%-210px)];
}

.populate-iframe-modal .populate-iframe-full {
  @apply !h-[calc(100%-16px)] !rounded-b-xl;
}

.populate-iframe-full {
  @apply h-full;
}
/** iFrame End */

/** Material Dynamic Overrides */
.MuiMenu-list {
  @apply py-0 !important;
}

.MuiMenu-list > li {
  @apply text-xs px-3 py-2;
}

.MuiMenu-list > li.Mui-selected {
  @apply bg-gray-200;
}

.MuiAutocomplete-popper .MuiAutocomplete-loading,
.MuiAutocomplete-popper .MuiAutocomplete-noOptions,
.MuiAutocomplete-listbox {
  @apply p-0 !important;
}

.MuiAutocomplete-option {
  @apply !text-xs !p-2;
}

.MuiAutocomplete-noOptions {
  @apply px-4 py-1.5 text-xs !important;
}

.MuiDayPicker-slideTransition {
  @apply min-h-[12.5rem] !important;
}

.MuiFormControlLabel-label {
  @apply !text-xs text-primaryLight !ml-1.5;
}

.MuiAutocomplete-popper .MuiPaper-root {
  @apply text-sm text-primary;
}

.MuiAutocomplete-popper .MuiPaper-root .MuiAutocomplete-listbox {
  @apply !p-2;
}

.MuiAutocomplete-popper .MuiAutocomplete-loading,
.MuiAutocomplete-popper .MuiAutocomplete-noOptions {
  @apply text-xs;
}

.patient-forms .Mui-checked {
  color: #2A85FE !important;
}

/** Visit Note Template Select **/
.template-select .MuiInputBase-root {
  @apply pr-3 !important;
}
.template-select .MuiInputBase-input#template-name {
  @apply flex order-2 !important;
}
.template-select .MuiAutocomplete-endAdornment {
  @apply flex order-1 static mr-2 !important;
}
.template-select .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
  @apply hidden !important;
}
.template-select .MuiAutocomplete-option {
  @apply px-1.5 !important;
}
.template-select .MuiAutocomplete-option.Mui-focused {
  @apply bg-[#E7EEFA] rounded !important;
}
.template-select:not(.relative) .MuiAutocomplete-popper {
  @apply static transform-none !important;
}
.template-select.relative .MuiAutocomplete-popper {
  @apply translate-x-0 translate-y-10 !important;
}
.template-select .MuiAutocomplete-listbox {
  @apply !max-h-fit !overflow-y-hidden;
}

/** Rich Text Editor Start */
.populate-editor-toolbar .ck.ck-toolbar {
  @apply !border-none !bg-transparent;
}

.populate-editor-toolbar .ck-icon {
  @apply !w-4 !h-4;
}

.populate-editor-toolbar .ck-button {
  @apply !cursor-pointer !rounded-full !m-0;
}

.populate-editor-toolbar .ck-button:focus {
  @apply !outline-none !border-none !shadow-none !bg-gray-300;
}

.populate-editor-toolbar .ck-button.ck-disabled {
  @apply !cursor-not-allowed;
}

.populate-editor-content .ck-editor,
.populate-editor-content .ck-editor__editable {
  @apply !h-full !bg-transparent !border-0;
}


.populate-editor .__populate__-field {
  @apply rounded bg-blue-100 text-blue-700 cursor-pointer;
}
/** Rich Text Editor End */

.empty-fill {
  fill: transparent !important;
}

button.is-pending[disabled] {
  @apply !bg-gray-100 !text-gray-700 !pointer-events-auto !cursor-not-allowed !opacity-50;
}


/** JUMBO Search Start */

.jumbo-search {
  @apply !inline-block w-full;
}

.jumbo-search .populate-autocomplete.disabled {
  @apply !bg-transparent;
}

.jumbo-search .MuiInputBase-root {
  @apply !p-0 ;
}

.jumbo-search input.MuiOutlinedInput-input,
.jumbo-search input.MuiOutlinedInput-input.Mui-disabled {
  @apply !rounded-full !bg-gray-200/10 !text-sm !border-gray-500 !border-solid !pr-3 !py-1 !pl-9 !w-full !text-white transition-colors duration-100 ease-in-out;
}

.jumbo-search .MuiIconButton-root  {
  @apply absolute left-2 top-1 text-white;
}

.jumbo-search .MuiIconButton-root svg {
  @apply !w-5 !h-5;
}

.jumbo-search input:focus-within,
.jumbo-search input:focus {
  @apply !border-b-gray-400 !bg-white placeholder:!text-black !text-black;
}

.jumbo-search .Mui-focused .MuiIconButton-root {
  @apply !text-black;
}

.jumbo-search .MuiInputBase-root,
.jumbo-search .MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: none !important;
}

/** JUMBO SEARCH END */

@import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
@import "./date-range-picker.css";
@import "./ck-editor.css";

/** MOBISCROLL START */
.mbsc-timeline-resource,
.mbsc-timeline-resource-header-cont,
.mbsc-timeline-header-date-cont {
  @apply !bg-gray-50;
}

.calendar-view-daily .mbsc-schedule-resource-title {
  @apply truncate;
}

.mbsc-schedule-resource,
.mbsc-timeline-resource {
  @apply flex justify-center;
}

.mbsc-timeline-resource {
  @apply flex-col;
}


.mbsc-timeline-day,
.mbsc-schedule-item {
  @apply !cursor-pointer;
}

.has-stripes,
.is-filtered-out,
.mbsc-schedule-event.is-time-block-slot .mbsc-schedule-event-background {
    background-image: linear-gradient(
            45deg, rgba(255, 255, 255, .45) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .45) 50%,
            rgba(255, 255, 255, .45) 75%,
            transparent 75%,
            transparent
    ) !important;
    @apply !bg-[length:1rem_1rem];
}

.mbsc-schedule-event.is-provider-availability-slot {
  @apply !w-0.5 !-left-0.5 hover:!w-4 hover:!-left-1
}

.mbsc-schedule-event.is-provider-availability-slot .content-container > *:not(.mbsc-schedule-event-bar):not(.mbsc-schedule-event-background) {
  @apply opacity-0;
}

.is-filtered-out {
  @apply !opacity-30 !border !border-gray-700 !rounded;
}

.mbsc-calendar-wrapper {
  @apply !hidden;
}

.mbsc-schedule-col-width {
  @apply !w-24;
}

.mbsc-schedule-event-inner {
  @apply !py-0;
}

.calendar-view {
  @apply !-mx-4;
}

.calendar-view-medium .mbsc-schedule-time-wrapper,
.calendar-view-medium .mbsc-schedule-item {
  @apply !h-6 !border-transparent;
}

.calendar-view-medium .mbsc-schedule-item:nth-child(2n) {
  @apply !border-gray-300;
}

.calendar-view-small .mbsc-schedule-time-wrapper,
.calendar-view-small .mbsc-schedule-item {
  @apply !h-3 !border-transparent;
}

.calendar-view-small .mbsc-schedule-item:nth-child(4n+2) {
  @apply !border-gray-300;
}

.calendar-view-small .mbsc-schedule-time-wrapper,
.calendar-view-small .mbsc-schedule-item {
  @apply !h-3 !border-transparent;
}

.calendar-view-small .mbsc-schedule-item:nth-child(4n+2) {
  @apply !border-gray-300;
}

.mbsc-eventcalendar {
  /* width of sidebar + margins lr */
  width: calc(100vw - 84px) !important;
}
.is-expanded .mbsc-eventcalendar {
  /* width of expanded sidebar + margins lr */
  width: calc(100vw - 224px) !important;
}
.event-calendar-schedule .mbsc-timeline-header-column {
  @apply items-center text-xs text-center;
}
.event-calendar-schedule .mbsc-timeline-header-column,
.event-calendar-schedule .mbsc-timeline-column {
  @apply first:border-l first:border-gray-300;
}
.event-calendar-schedule .mbsc-timeline-header-column:last-child,
.event-calendar-schedule .mbsc-timeline-column:last-child {
  @apply !border-r !border-gray-300;
}
.event-calendar-schedule .mbsc-schedule-event-background {
  @apply !mt-0;
}
/** MOBISCROLL END */

/** Switch Start */
.MuiSwitch-sizeMedium {
  @apply !p-0 !w-[32px] !h-[16px];
}

.MuiSwitch-sizeMedium .MuiSwitch-switchBase {
  @apply p-0 m-0.5 duration-300;
}

.MuiSwitch-switchBase.Mui-checked {
  @apply text-white !translate-x-4;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    @apply !bg-blue-500 !border-0 !opacity-100;
}

.MuiSwitch-sizeMedium .MuiSwitch-thumb {
  @apply bg-white box-border w-3 h-3;
}

.MuiSwitch-sizeMedium .MuiSwitch-track {
  @apply rounded-xl;
}
/** Switch End */

/** Badge Start */
.stickies-count > .MuiBadge-badge {
  @apply text-xs !h-4 !min-w-[1rem] !px-[3px];
}

/** Badge End */

/** Tree View Start */
.populate-tree-view .MuiCollapse-root,
.populate-tree-view li {
  @apply relative;
}

.populate-tree-view .MuiTreeItem-root .Mui-expanded {
  @apply !h-5;
}

.populate-tree-view .MuiTreeItem-root .Mui-expanded,
.populate-tree-view .Mui-expanded + .MuiCollapse-root,
.populate-tree-view .Mui-expanded + .MuiCollapse-root .MuiTreeItem-root {
  @apply before:content-[''] before:top-[10px] before:block before:absolute before:bg-gray-400;
}

.populate-tree-view .MuiTreeItem-root .Mui-expanded {
  @apply before:h-px before:w-2 before:left-0;
}

.populate-tree-view .Mui-expanded + .MuiCollapse-root {
  @apply before:w-px before:h-[calc(100%-3px)] before:left-[-17px] before:top-[-10px];
}

.populate-tree-view .Mui-expanded + .MuiCollapse-root .MuiTreeItem-root:not(.is-empty) {
  @apply before:h-px before:w-8 before:top-[calc(50%+10px)] before:-left-4;
}

.populate-tree-view .Mui-expanded + .MuiCollapse-root .MuiTreeItem-root:not(.is-empty).is-header {
  @apply before:top-[calc(50%+20px)];
}

.populate-tree-view .Mui-expanded + .MuiCollapse-root .MuiTreeItem-root:not(.is-empty).is-header.has-subheader {
  @apply before:top-[calc(50%+36px)];
}
/** Tree View End */

/** Doc View Start */
.populate-doc-view .title {
  @apply text-lg font-semibold my-2;
}

.populate-doc-view .policy-text {
  @apply text-base font-semibold my-2;
}

.populate-doc-view p {
  @apply text-sm;
}
/** Doc View End */

/** Breakdown Table Start */
.populate-breakdown-table .populate-table-header {
  @apply !border-0;
}
.populate-breakdown-table .populate-table-body {
  @apply !px-0 !mx-0;
}

.populate-breakdown-table .populate-table-body  > div > div {
  @apply !pb-0 !px-0;
}

.populate-breakdown-table .populate-table-row-header {
  @apply !p-0 !border-b !border-b-gray-200 !text-[10px];
}

.populate-breakdown-table .populate-table-row-footer {
  @apply !border-t-0;
}

.populate-breakdown-table .populate-table-row-footer .populate-table-cell,
.populate-breakdown-table .populate-table-row-header .populate-table-cell {
  @apply !text-gray-800;
}

.populate-breakdown-table .populate-table-row-header,
.populate-breakdown-table .populate-table-row-footer {
  @apply !bg-gray-100;
}

.populate-breakdown-table .populate-table-header {
  @apply mr-0;
}

.populate-breakdown-table .populate-table-header.visit-breakdown-header,
.populate-breakdown-table .populate-table-footer.visit-breakdown-footer {
  @apply !mr-0;
}

.populate-breakdown-table .populate-table-row-header .populate-table-cell > div {
  @apply !justify-center !text-gray-800;
}

.populate-breakdown-table .populate-table-cell {
  @apply border-r border-r-gray-200 !h-9;
}

.populate-breakdown-table .populate-editable-idle {
  @apply !shadow-none;
}

.populate-breakdown-table .populate-editable-idle span:empty ~ .populate-editable-trigger {
  @apply !ml-0;
}

.populate-breakdown-table .populate-table-cell .populate-input.populate-currency-input .MuiOutlinedInput-notchedOutline {
  @apply !rounded-none !border-none !w-[calc(100%+8px)] !h-[calc(100%+8px)] !-ml-1 !mt-[-1px];
}

.populate-breakdown-table .populate-table-cell .populate-input.populate-currency-input {
  @apply !bg-transparent;
}

.populate-breakdown-table .populate-table-cell .populate-input.populate-currency-input .MuiOutlinedInput-input {
  @apply !text-gray-700 !font-semibold !px-0;
}

.populate-breakdown-table .populate-table-cell .populate-input.populate-currency-input.negative-input .MuiOutlinedInput-input {
  @apply !text-red-500 !font-semibold;
}
.populate-breakdown-table .editable-currency-input-container:focus-within {
  @apply  !border-2  !border-blue-500;
}
.populate-breakdown-table .populate-table-cell .populate-input.populate-currency-input .MuiOutlinedInput-input:focus {
  @apply  !font-normal !pl-2;
}

.populate-breakdown-table .group {
  @apply !rounded-none !border-x-0 !border-t-0 !border-b-gray-200;
}

.populate-breakdown-table .populate-table-row .populate-table-cell {
  @apply relative;
}

.populate-breakdown-table .populate-table-row .populate-table-cell:last-child {
  @apply !border-r-0;
}
/** Breakdown Table End */

/** Service table starts */
.populate-services-table .populate-table-row .populate-table-cell {
  @apply !px-1.5 !text-2xs;
}

.populate-services-table  .populate-table-row .populate-table-cell {
  @apply border-r border-b-gray-200;
}

.populate-services-table  .populate-table-row .populate-table-cell:last-child {
  @apply !border-r-0;
}

.populate-services-table .populate-table-header .populate-table-row-header {
  @apply !p-0 !border-0;
}

.populate-services-table  .populate-table-row .populate-table-cell:not(:first-child) {
  @apply px-3;
}

.populate-services-table .MuiInputBase-input {
    @apply !text-[11px] !h-[14px] !p-1;
}

.populate-services-table .MuiAutocomplete-inputRoot {
  @apply !py-0 !pl-0;
}

.populate-table-row .populate-table-cell.blocked-cell {
  background-image: linear-gradient(
            45deg, rgba(255, 255, 255, .45) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .45) 50%,
            rgba(255, 255, 255, .45) 75%,
            transparent 75%,
            transparent
    );
    @apply h-9 bg-gray-100 !bg-[length:1rem_1rem] !border-r-0 text-transparent;
}

.populate-table-row .populate-table-cell.blocked-cell + .populate-table-cell:not(.blocked-cell) {
  @apply border-l;
}
/** Service table ends */

/** Misc Dialogs Start */
.populate-dialog.is-full .MuiDialog-paperFullWidth{
  @apply !max-h-[initial] !h-[calc(100vh-24px)] left-[30px] !w-[calc(100vw-84px)] !max-w-[initial] rounded-lg;
}
/** Misc Dialogs End */

.populate-form label {
  @apply font-semibold text-xs text-gray-500 truncate;
}

.populate-divider:before {
  @apply !w-0;
}

.populate-vertical-list-text > .populate-list-item:not(:last-child) {
  @apply after:text-inherit after:content-['•'] after:ml-2;
}

.populate-code-input .MuiOutlinedInput-notchedOutline {
  @apply !border-none;
}

.populate-code-input .MuiOutlinedInput-input {
  @apply bg-blue-200 !w-[4ch] !p-0;
}
.populate-phone-input fieldset {
  @apply !border;
}
.populate-phone-input .MuiOutlinedInput-root {
  @apply bg-gray-100;
}
.populate-phone-input .MuiOutlinedInput-input {
  @apply bg-white rounded-r-[inherit] h-4;
}
.MuiMenuItem-root {
  @apply !p-2;
}
.MuiMenuItem-root.populate-menu-item {
  @apply !p-0;
}

@container charts-main (width < 1000px) {
  .populate-route-tabs { @apply !min-h-[32px]; }
  .populate-route-tabs:not(.populate-panel-navigation) .MuiTab-root {
    @apply !text-[10px] !w-fit !min-w-fit !min-h-[32px] !flex-col;
  }
  .populate-route-tabs .populate-icon-wrapper { @apply !mr-0; }
  .insurance-info { @apply !w-full; }

}

.populate-charts-main {
  container-name: charts-main;
  container-type: size;
}

.populate-claim-form {
  container-name: claim-form;
  container-type: size;
}

.populate-magic-button > button {
  @apply transition-colors duration-100;
  background: linear-gradient(135deg, rgba(70,158,252,1) 44%, rgba(30,132,241,1) 100%);
}

.populate-magic-button:hover > button,
.populate-magic-button:active > button {
  background: linear-gradient(135deg, rgba(30,132,241,1) 100%, rgba(70,158,252,1) 44%);
}